export default defineNuxtRouteMiddleware(async (to: any, from: any) => {
    if (import.meta.client) return;

    const config = useRuntimeConfig();

    // Prevent robot.txt to show every request
    if (to.path.includes('robot.txt')) return;

    // Redirect /gb to /uk
    const gbRegex = /\/gb/;
    if (gbRegex.test(to.path)) {
        const query: string = new URLSearchParams(to.query).toString();
        to.path = to.path.replace(gbRegex, '/uk');
        return navigateTo(to.path + query, {
            external: true,
            redirectCode: 301,
        });
    }

    // Retain query URL
    const queryURL: URLSearchParams = new URLSearchParams(from.query);
    const query: string = queryURL.toString() ? `?${queryURL}` : '';

    // Get Storyblok cache version
    const storyblokApi = useStoryblokApi();
    const cv = await storyblokApi.get('cdn/spaces/me');
    const startsWith = `${config?.public.STORYBLOK_FOLDER}/global/redirects/`;

    const perPage = 100;
    const params = {
        version: config?.public?.STORYBLOK_VERSION,
        per_page: perPage,
        page: 1,
    };

    // Get URL without trailing slash
    const toUrl: string = to.path.endsWith('/') ? to.path.slice(0, to.path.length - 1) : to.path;

    let stories: any = [];

    const url = `https://api.storyblok.com/v2/cdn/stories?starts_with=${startsWith}&filter_query[source_url][in]=${toUrl}&per_page=${params.per_page}&page=1&version=${config?.public?.STORYBLOK_VERSION}&token=${config?.public.STORYBLOK_TOKEN}&cv=${cv.data.space.version}`;

    // Get Data from Storyblok (UseFetch to cache result)
    const { data }: any = await useAsyncData(`storyblok-redirect-first`, () => $fetch(url));
    stories = stories.concat(data?.value?.stories);

    if (stories.length === 0) return;

    // Get result from Storyblok results, if no results don't redirect
    const redirect = stories?.filter((r: { content: { source_url: string } }) => {
        return r?.content?.source_url === toUrl;
    })[0];
    if (!redirect) return;

    // Check if URL + Storyblok target is the same, if so don't redirect
    if (redirect?.content?.target_story === toUrl) return;

    return navigateTo(redirect?.content?.target_story + query, {
        external: true,
        redirectCode: Number(redirect?.content?.status_code),
    });
});
